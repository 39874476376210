@use "/src/sass/_colors.scss" as colors;

.menuWrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  min-height: 40px;
  padding: 0 16px 32px 16px;

  &.pdBt28 {
    padding: 0 16px 28px 16px;
  }

  @media (max-width: 420px) {
    justify-content: left;
  }

  .menuItem {
    background: colors.$terraWhite;
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    width: auto;
    padding: 0 15px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    height: 40px;
    color: colors.$main;

    &:disabled {
      border: 1px solid colors.$main;
      color: colors.$main;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
