.barcode {
  margin-bottom: 61px;
  max-width: 100%;
  height: auto;
  backdrop-filter: blur(0);
}

.brandLogo {
  width: 52px;
  height: auto;
  min-width: 170px;
  margin-bottom: 0.5px;
}

.code {
  margin-bottom: 21px;
  backdrop-filter: blur(0);

  .bolder {
    font-weight: 600;
  }
}

.background {
  backdrop-filter: blur(15px) brightness(70%);
}

.canvasBarcode {
  width: 100%;
}

.wrapper:not(.verticalDirection) {
  padding-top: 56px;
  padding-bottom: 30px;

  .closeBtn {
    top: 40px;
  }

  .brandLogo {
    width: auto;
    height: 28px;
    min-width: auto;
    margin: 15px auto;
    display: block;
  }
}

.verticalDirection {
  &.rotatedCentering {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: calc(100vh - 50px);
  }
  .rotatedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: rotate(90deg);
    overflow: hidden;
  }
  .canvasBarcode {
    max-width: calc(100vh - 100px);
  }
}
