.container {
  padding: 0 16px;
  margin-bottom: 96px;

  .text {
    margin-top: 57px;
    margin-bottom: 28px;
  }

  .monoprixCard {
    width: 100%;
    height: auto;
    margin-bottom: 31px;
  }
}
