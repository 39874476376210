@use "/src/sass/_colors.scss" as colors;

.retailerList {
  padding: 0 16px;
  flex-grow: 1;

  .retailerItem {
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 14px 15px;
    height: 78px;

    &:not(.disabled) {
      box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
      background: colors.$terraWhite;
    }

    &.disabled {
      border: 2px solid colors.$main;
    }

    .learnMore {
      white-space: nowrap;
    }

    .retailerLink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .retailerContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .iconContainer {
          height: 50px;
          width: 50px;
          border: 1px solid #dedede;
          border-radius: 15px;
          display: flex;
          align-content: center;
          justify-content: center;
          margin-right: 20px;

          .retailerIcon {
            width: 100%;
            height: auto;
            border-radius: 15px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
