.takePhotoWrapper {
  width: 100%;
  padding: 0 16px;

  .takePhotoNav {
    display: flex;
    height: 85px;
    max-width: 768px;
    margin: auto;
  }
}
