@use "/src/sass/_animations.scss" as animations;
@use "/src/sass/_colors.scss" as colors;

@keyframes moveToBottom {
  to {
    transform: translateY(100%) rotateZ(0.01deg);
  }
}

.wrapper {
  border-radius: 30px 30px 0px 0px;
  padding: 22px 16px 20px;
  z-index: 20 !important;

  &:global(.enter-active) {
    @include animations.animation(animations.$moveFromBottom);
  }

  &:global(.exit-active) {
    @include animations.animation(moveToBottom);
  }

  header {
    padding: 0 6px;
  }

  .tools {
    margin-top: 10px;
    border-radius: 20px;
    padding: 14px 16px;

    .navigateIcon {
      position: relative;
      right: 1.54px;
      top: 1.21px;
    }

    .left {
      padding-right: 15px;

      &::after {
        content: "";
        width: 2px;
        display: block;
        background-color: #d6d6d6;
        height: 54px;
        position: absolute;
        right: -1px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .right {
      padding-left: 15px;

      > a {
        width: 50%;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;

        div + div {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .details {
    margin-top: 10px;
    padding: 0 6px;

    .hours {
      margin-top: 2px;
    }

    .tel {
      margin-top: 1px;

      &::before {
        content: "";
        display: inline-block;
        background-image: url(../../../assets/icons/telephone.svg);
        width: 12px;
        height: 12px;
        vertical-align: baseline;
        margin-right: 5px;
      }
    }
  }

  .dropOff {
    margin-top: 12px;
  }

  .description {
    font-size: 12px;
    line-height: 22px;
    width: 100%;
    font-weight: 400;
    color: colors.$main;
  }
}

.iconRow {
  svg {
    width: calc(100% / 6);
    height: auto;
    max-height: 33px;
    max-width: 33px;
  }
}
