@use "/src/sass/_colors.scss" as colors;

.emailCheckWrap {
  padding-top: 40px !important;

  .wrapper {
    :global(.pw-description) {
      margin-bottom: 20px;
    }

    .imageRow {
      margin-bottom: 30px;

      svg {
        display: block;
        margin: auto;
      }
    }

    :global(.text-center) {
      margin-bottom: 8px;

      &.email {
        margin-bottom: 8px;
        color: colors.$main;
      }
    }

    .linkRow {
      display: flex;
      justify-content: center;
      margin: 30px 0 33px;
    }

    .spam {
      font-weight: 400;
      font-size: 13px;
      line-height: 25px;
      text-align: center;
      color: colors.$textSecondary;
      margin-bottom: 30px;
    }
  }
}
