@use "/src/sass/_text.scss" as text;
@use "/src/sass/_colors.scss" as color;

.coupon {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: color.$terraWhite;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 14px 24px 35px 24px;
  text-align: left;
  position: relative;

  .landingBtn {
    text-align: left;
  }

  .unlockButton {
    .unlockText {
      margin-left: 10px;
    }
  }

  .topPart {
    border-bottom: 1px dashed color.$textSecondary;
    margin-bottom: 9px;
    align-items: center;
    width: 100%;

    .percent {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      color: color.$main;
    }
  }

  .text {
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    color: color.$textPrimary;
    margin-bottom: 13px;
  }
}

.available {
  color: #898989;
  margin-bottom: 21px;

  &.active {
    color: #1c8f61;
  }

  &.landing {
    margin-top: 3px;
    margin-bottom: 0;
  }
}

.unlockButton {
  margin-bottom: 11px;
}
