.container {
  margin-bottom: 64px;

  .title {
    margin-top: 40px;
    margin-bottom: 18px;
  }

  .text {
    text-align: center;
    margin-bottom: 30px;
  }

  .linkBtn {
    width: 100%;
    margin-bottom: 20px;
  }

  .cancelBtn {
    margin-bottom: 30px;
  }
}
