@use "/src/sass/_colors.scss" as colors;

.container {
  width: 100%;
  max-width: 768px;
  margin: 39px auto auto;
  display: flex;
  flex-direction: column;
}

.text {
  margin: auto;
  margin-bottom: 23px;
  max-width: 98%;
}

.takePicture {
  margin-bottom: 30px;
}
.manually {
  margin-bottom: 50px;
}

.dontHave {
  margin: auto auto 23px;
  max-width: 80%;
}
.createNow {
  margin-bottom: 48px;
  font-size: 15px;
  line-height: 24px;
  color: colors.$main;
  align-self: center;
  text-align: center;

  a {
    font-weight: bold;
  }
}

.image {
  width: calc(0.47 * (100% + 32px));

  & + .image {
    margin-top: 12px;
    margin-bottom: 20px;
  }
}

.skipAndStart {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: colors.$main;
  align-self: center;
}
