@use "/src/sass/_colors.scss" as colors;

.feedbackSurvey {
  display: flex;
  width: 100%;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: colors.$terraWhite;
  border-radius: 15px;

  .description {
    color: colors.$textPrimary;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 166.667% */

    &.link {
      color: colors.$main;
      font-weight: 700;
    }
  }
}
