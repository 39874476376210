@use "/src/sass/_colors.scss" as colors;
$error: "../../assets/icons/error.svg";
$success: "../../assets/icons/success.svg";

.backDrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(108, 108, 108, 0.4);
  backdrop-filter: blur(15px);
  max-width: none;
  max-height: none;
  z-index: 10;
}

.messageWrapper {
  padding: 20px 16px;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(48, 48, 48, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;

  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    background-color: colors.$success;
    flex-shrink: 0;
  }

  &:global(.error)::before {
    background-color: colors.$error;
    background-image: url($error);
  }

  &:global(.success)::before {
    background-color: colors.$success;
    background-image: url($success);
  }

  :global(.message-content) {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }

  :global(.close) {
    width: 24px;
    height: 24px;
  }
}
