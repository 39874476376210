@use "/src/sass/_colors.scss" as colors;

.icon {
  align-self: center;
  margin-top: 4px;
  margin-bottom: 32px;
  height: 77px;
  width: auto;
}
.historyPage {
  padding-top: 49px;

  @media (min-width: 768px) {
    max-width: 80% !important;
    margin: auto;
  }

  .link {
    text-align: center;

    .linkBtn {
      margin: 21px auto 0px auto;
      max-width: 500px;
    }

    .linkText {
      margin-top: 29px;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: colors.$main;
    }
  }

  .stepsContainer {
    width: 100%;
    @media (min-width: 1300px) {
      display: flex;
      justify-content: center;
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 1300px) {
        max-width: 315px;
        margin: 0 15px;
      }

      .stepTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: colors.$textPrimary;
        margin-bottom: 11px;
      }

      .stepText {
        font-weight: 400;
        max-width: 315px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        color: colors.$textPrimary;
        min-height: 75px;
        margin-bottom: 9px;
      }
    }
  }
}
