.learnMoreBtn {
  width: 21px;
  height: 21px;
  background-image: url(../../assets/icons/learn-more.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 4px;
  vertical-align: sub;
}
