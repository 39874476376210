@use "/src/sass/_colors.scss" as colors;

.icon {
  align-self: center;
  margin-top: 4px;
  margin-bottom: 32px;
  height: 77px;
  width: auto;
}

.continueBtn {
  max-width: 500px;
  margin: 0 auto;
}

.wrapper {
  text-align: center;
  color: colors.$textPrimary;

  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }

  p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 78px;
  }

  img {
    display: block;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    margin-bottom: 22px;
  }
}
