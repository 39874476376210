@use "reset";
@use "text";
@use "utils";
@use "animations";
@use "/src/sass/_colors.scss" as colors;

:root {
  --bs-gutter-x: 16px;
}

.tool-btn {
  @include utils.tool-btn;
}

.bottom-curved {
  @include utils.bottom-curved;
}

* {
  font-family: "Poppins", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

.enter {
  .hide-on-enter,
  &.hide-on-enter {
    display: none !important;
  }
}

.exit {
  .hide-on-exit,
  &.hide-on-exit {
    display: none !important;
  }
}

// font styles for text edited by RichTextEditor(CKEditor)
.default-font,
.ck-content {
  font-size: 15px;
  line-height: 24px;

  ul {
    list-style-type: disc;
    padding-left: 25px;
  }
  ol {
    list-style-type: decimal;
    padding-left: 25px;
  }

  blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.text-tiny,
.ck-content .text-tiny {
  font-size: 12px;
  line-height: 21px;
}

.text-small,
.ck-content .text-small {
  font-size: 13px;
  line-height: 22px;
}

.text-big,
.ck-content .text-big {
  font-size: 17px;
  line-height: 28px;
}

.text-huge,
.ck-content .text-huge {
  font-size: 20px;
  line-height: 32px;
}

.ck-content {
  min-height: 150px;

  ol,
  ul {
    padding-left: 25px;
  }
}

figure.image {
  img {
    width: 100%;
    height: auto;
  }
}

.ck-content {
  figure.image {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.MuiScopedCssBaseline-root {
  background-color: transparent !important;
}

.ot-sdk-show-settings {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: colors.$main;
  text-align: center;
  margin-top: 20px;

  &:hover {
    color: colors.$main;
  }
}

#ot-sdk-cookie-policy {
  flex-grow: 1;
  flex-shrink: 0;
}
