@use "/src/sass/_colors.scss" as colors;

.wrapper {
  .description {
    margin-bottom: 52px;
  }

  .learnMoreBtn {
    width: 21px;
    height: 21px;
    background-image: url(../../assets/icons/learn-more.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px;
    vertical-align: sub;
  }

  .codeInput {
    margin-bottom: 30px;

    .inputWrapper {
      border-radius: 30px;
      padding: 11px 15px;

      input {
        text-align: center;
        width: 15px;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: colors.$textPrimary;
        caret-color: colors.$textPrimary;

        &::placeholder {
          color: colors.$textSecondary;
        }
        &:focus::placeholder {
          color: transparent !important;
        }
      }
    }

    @media (min-width: 768px) {
      span {
        padding: 0 20px;
      }
    }
  }

  .descriptionBottom {
    margin-top: 25px;
    margin-bottom: 42px;
  }

  .noId {
    margin-bottom: 20px;
  }

  .noBgBtn {
    margin-top: 12px;
    margin-bottom: 26px;
  }

  .linkRow {
    display: flex;
    justify-content: center;
    margin: 20px 0 50px;
  }
}
