@use "/src/sass/_colors.scss" as colors;

.container {
  max-height: 81.1%;
  margin-top: 41px;
}

.heading {
  margin-top: 58px;
  margin-bottom: 12px;
  align-self: flex-start;
}

.applyBtn {
  margin-top: 42px;
  margin-bottom: 80px;
}

.retailerWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;

  .iconContainer {
    height: 50px;
    width: 50px;
    border: 1px solid #dedede;
    border-radius: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-right: 20px;

    .retailerIcon {
      width: 100%;
      height: auto;
      border-radius: 15px;
      object-fit: contain;
    }
  }
}

.retailerList {
  width: 100%;
  overflow-y: auto;

  .retailerItem {
    padding: 16px 4px;
    border-bottom: 1px solid colors.$grey;

    &:last-child {
      border: none;
    }
  }
}

.checkWrapper {
  input {
    width: 0;
    height: 0;
    appearance: none;

    &:focus + .check-button {
      border: 2px solid colors.$main;
    }
  }

  .inputRow {
    display: flex;
    align-items: center;
  }

  .checkButton {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: block;
    background-color: colors.$grey;
    flex-shrink: 0;
    position: relative;
    margin-right: 12px;

    label {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      display: none;
    }
  }

  input:checked + .checkButton {
    background-color: colors.$main;

    svg {
      display: block;
    }
  }
}
