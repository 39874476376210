@use "/src/sass/_colors.scss" as colors;

.iconBtn {
  background: none;
  border: none;
}

.searchWrapper {
  width: 100%;
  display: flex;
  max-width: 768px;
  position: relative;
  padding: 10px 15px;
  background-color: colors.$terraWhite;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.17);
  border-radius: 24px;
  margin-top: 33px;
  margin-bottom: 17px;

  .leftIcon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    flex-shrink: 0;
  }

  .rightIcon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 15px;
    flex-shrink: 0;
  }
}

.searchInput {
  width: 100%;
  display: flex;
  background-color: transparent;
  padding-left: 34px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: colors.$textPrimary;
  max-width: calc(100% - 24px);
  overflow: hidden;

  &::placeholder {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: colors.$textPrimary;
  }
}
