@use "/src/sass/_colors.scss" as colors;

.carouselContainer {
  width: 100%;

  :global(.swiper-wrapper) {
    padding-bottom: 78px;
  }

  .carouselItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    background-color: colors.$terraWhite;
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    height: auto;

    @media (min-width: 992px) {
      padding: 24px 30px;
    }

    .brandContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 19px;

      .brandName {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        color: colors.$main;
      }

      .brandIcon {
        width: auto;
        max-height: 22px;
      }
    }

    .noLink {
      opacity: 0;
      pointer-events: none;
    }

    .shopPhoto {
      max-width: 100%;
      width: 100%;
      height: 113px;
      border-radius: 20px;
      margin-bottom: 19px;
      object-fit: contain;
    }

    .description {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      color: colors.$textPrimary;
      margin-bottom: 19px;
    }

    .createNow {
      margin-bottom: 19px;
    }

    .whatToRecycle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      color: colors.$textPrimary;
      margin-bottom: 10px;
    }

    .registerLink {
      width: 100%;
    }
  }
}

.page {
  .pageContent {
    padding-top: 16px;
  }
}

.topText {
  margin-bottom: 16px;
}

.navBtn {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: colors.$terraWhite;
  filter: drop-shadow(0px 14px 20px rgba(0, 0, 0, 0.05));
  position: absolute;
  top: 120px;
  right: 8px;
  z-index: 2;

  svg {
    width: 21px;
    height: 21px;
    fill: colors.$textPrimary;
  }

  &.navBtnPrev {
    left: 8px;
    right: auto;
    svg {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    display: none;
  }
}
