@use "/src/sass/_colors.scss" as colors;

.mapPointListWrapper {
  min-height: 100%;
  width: 100%;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  transition: opacity 0.5s;

  .description {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: colors.$textSecondary;
    margin-bottom: 16px;
  }

  .otherRetailers {
    margin-top: 16px;
  }
}

.mapPointListContainer {
  width: 100%;
  max-width: 768px;
  margin-top: 94px;
}

.descriptionContainer {
  display: flex;
  width: 100%;
}

.locationContainer {
  background-color: colors.$terraWhite;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  overflow: hidden;
  padding: 15px 24px 15px 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: flex-start;
  text-align: left;
}

.locationDescriptionContainer {
  display: flex;
  align-items: flex-start;

  img {
    width: 48px;
    margin-right: 6px;
    min-height: 24px;
  }
}

.locationDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: colors.$textPrimary;
}

.locationTitle {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: colors.$textPrimary;
  margin-bottom: 6px;
}
