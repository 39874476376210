.wrapper {
  padding-top: 85.5px;
  padding-bottom: 85.5px;

  .title {
    margin-bottom: 18px;
  }

  .text {
    margin-bottom: 30px;
    text-align: center;
  }
  .deleteBtn {
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 18px;
  }
}
