@use "/src/sass/_colors.scss" as colors;

.deleteProductContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: colors.$delete;
  border-radius: 0 15px 15px 0;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: colors.$terraGrey;
}

.deleteText {
  margin-top: 16px;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  color: colors.$terraWhite;
}

.scanItemLink {
  position: fixed;
  width: 50px;
  height: 50px;
  left: calc(100% - 60px);
  top: calc(100% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$terraGreen;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.17);
  border-radius: 100%;
  flex-shrink: 0;

  @media (min-width: 768px) {
    left: min(calc(100% - 60px), 85%);
  }

  @media (min-width: 991px) {
    left: min(calc(100% - 60px), 71%);
  }
}

.topDescription {
  margin-top: 0;
  margin-bottom: 14px;
}

.categoryIcon {
  width: 33px;
  height: 33px;
}

.page {
  padding-bottom: 121px;
}
