.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;

  .signIn {
    border-radius: 3px;
    padding: 6px 16px;
    background-color: rgb(25, 118, 210);
    color: white;
    margin-top: 10px;
    min-width: 150px;
  }
}

.setupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  background-color: #6762a6;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 100px;

  &.login {
    background-color: #fafafb;
  }
}

h1.title {
  color: white;
  text-align: center;
  margin-bottom: 26px;
  margin-top: 17px;
  font-size: 25px;
}

.inputWrapper {
  align-self: start;
  width: 100%;
}

input.input {
  color: #6762a6;
  border: 1px solid #6762a6;
  background-color: white;
  margin-bottom: 16px;
  width: 100%;
  margin-top: 20px;
}

.description {
  margin-top: 10px;
  margin-bottom: 16px;
  color: white;
}
