$main: #1b8158;
$secondary: #f1f1f1;
$disabled: #e7e9e7;
$disabledText: rgba(19, 34, 15, 0.3);
$textPrimary: #13220f;
$textSecondary: #898989;
$textBlack: #2a2a37;
$terraGreen: #005c35;
$terraGrey: #f7f7f7;
$terraWhite: #ffffff;
$error: #ca4b2b;
$delete: #c43f3f;
$success: #60d695;
$grey: #dedede;
$secondaryGreen: #e9f3ef;
$disabledInputText: #acacac;
$navGrey: #aeaeae;
$lightGreen: #a9de98;
$successLight: #81eb9f;
$successMedium: #60d695;
$darkGrey: #4a4a4a;

$theme: (
  "main": $main,
  "secondary": $secondary,
  "disabled": $disabled,
  "disabledText": $disabledText,
  "textPrimary": $textPrimary,
  "textSecondary": $textSecondary,
  "textBlack": $textBlack,
  "terraGreen": $terraGreen,
  "terraGrey": $terraGrey,
  "terraWhite": $terraWhite,
  "error": $error,
  "delete": $delete,
  "success": $success,
  "grey": $grey,
  "secondaryGreen": $secondaryGreen,
  "disabledInputText": $disabledInputText,
  "navGrey": $navGrey,
  "lightGreen": $lightGreen,
  "successLight": $successLight,
  "successMedium": $successMedium,
  "darkGrey": $darkGrey,
);
