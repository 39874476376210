@use "/src/sass/_colors.scss" as vars;

.camera-wrapper,
.cameraWrapper {
  .camera-video,
  .cameraVideo {
    width: 161px;
    height: 161px;
    object-fit: cover;
    border-radius: 20px;
  }

  .camera-photo,
  .cameraPhoto {
    width: 161px;
    height: 161px;
    object-fit: cover;
    border-radius: 20px;

    position: absolute;
    top: 0;
    z-index: 2;
  }

  .camera-canvas,
  .cameraCanvas {
    display: none;
  }

  .camera {
    width: 161px;
    display: block;
    margin: auto;
  }

  .output {
    width: 161px;
    display: block;
    margin: auto;
  }

  .photo-button,
  .photoButton {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: vars.$main;

    position: absolute;
    bottom: -25px;
    transform: translateX(-50%);
    left: 50%;
    right: 50%;
    z-index: 3;
  }

  .content-area,
  .contentArea {
    position: relative;
    margin-bottom: 47px;
  }
}
