@use "/src/sass/_colors.scss" as vars;

.noItems {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .circleBinIcon {
    position: relative;
    margin-bottom: 50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      bottom: -20px;
      display: block;
      width: 137px;
      height: 137px;
      border-radius: 100%;
      background-color: vars.$terraWhite;
    }

    .bin-icon {
      z-index: 3;
    }
  }
}

.binWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 61px;

  .empty-text {
    width: 300px;
    text-align: center;
  }
}

.productContainer {
  display: flex;
  height: 80px;
  width: 100%;
  flex-direction: row;
  background-color: vars.$terraWhite;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;

  &.dropOff {
    position: relative;

    &.active {
      background: rgba(169, 222, 152, 0.5);
      border: 1px solid #94ceb7;
    }
  }
}

.productDescription {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 12px;
  flex-grow: 1;

  &.dropOff {
    margin-left: 96px;
  }
}

.productBrand {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 6px;
  color: vars.$textPrimary;
}

.productCategory {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: vars.$textPrimary;
}

.productImage {
  width: 80px;
  object-fit: cover;
  border-radius: 15px 0px 0px 15px;

  &.dropOff {
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;

    &.active {
      top: -1px;
      left: -1px;
    }
  }
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-right: 15px;
  width: 52px;
}

.categoryName {
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  color: vars.$main;
  max-width: 56px;
}
