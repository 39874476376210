.noCoupons {
  display: flex;
  min-height: 50%;
  flex-direction: column;
  align-items: center;
  margin-top: 63px;
  justify-content: center;

  .icon {
    margin-bottom: 30px;
  }
}
