@use "/src/sass/_colors.scss" as vars;

.footerNavWrapper {
  background-color: vars.$terraWhite;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.2);
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: 0.3s;

  @media (min-width: 768px) {
    border-radius: 0;
  }

  .navText {
    font-weight: 500;
    font-size: 9px;
    line-height: 16px;
    color: vars.$navGrey;
    text-align: center;
  }

  .footerNavNav {
    display: flex;
    justify-content: space-around;
    padding-bottom: 8px;
    padding-top: 11px;
    max-width: 768px;
    width: 100%;
  }

  .footerNavLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0;

    .navIcon {
      margin-bottom: 1px;
    }

    &.active {
      .navIcon {
        path {
          fill: vars.$main;
        }
      }

      p {
        color: vars.$main;
      }
    }
  }
}
