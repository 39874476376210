@use "/src/sass/_colors.scss" as colors;

.homeCouponCarousel {
  width: 100%;
  margin-bottom: 16px;
}
.homeCouponCarouselContainer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 20px;
}
.homeCouponCarouselItem {
  width: 100px;
  background: #ffffff;
  border-radius: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.05);
}

.divider {
  border-top: 1px solid colors.$textSecondary;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 0.3;
}

.homeCouponCarouselDiscount {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #1b8158;
}

.homeCouponCarouselUpToText {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #13220f;
}

.homeCouponCarouselImages {
  height: 39px;
  width: auto;
  max-width: calc(100% - 20px);
  object-fit: contain;
}

.headerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 55px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 1px;
}

.logoHeaderContainer {
  height: auto;
  width: 80px;
  margin-top: 0;
  margin-bottom: 0;
}

.bubbleContainer {
  padding: 0 0px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 15px;
}

.howDoesItWork {
  padding-top: 19px;
  padding-left: 18px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
}

.bubbleHomeIcons {
  height: 33px;
}

.brandLogo {
  height: 28px;
}

.homeContainer {
  min-height: 100%;
  padding: 0 15px;

  &.forHome {
    padding-bottom: 80px;
  }

  .wrapper {
    @media (min-width: 768px) {
      max-width: 50%;
    }

    :global(.bubble-icon) {
      margin-top: 3px;
      margin-right: 26px;
      width: auto;
      height: 33px;

      &:global(.with-steps) {
        margin-right: 0;
        margin-left: 10px;
      }

      &:global(.special-case) {
        margin-right: 5px;
      }
    }

    :global(.home-bubble) {
      min-height: 80px;
      padding: 10px 10px;

      &:global(.with-steps) {
        justify-content: space-between;
      }
    }

    :global(.removeBoxShadow) {
      box-shadow: none;
      border-radius: 0px;
      padding: 10px 18px;
      margin-bottom: 28px;
    }

    :global(.removeTextAlign) {
      align-items: stretch;
      margin-bottom: 0px;
    }

    :global(.step-text-wrapper) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    :global(.step-text-wrapper-container) {
      display: flex;
    }
  }

  .styleHeading {
    margin-left: 20px;
  }
}

.bannerWrap {
  display: flex;
  width: 100%;
  height: 60px;
  position: relative;
  background: #e9f3ef;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 5;

  &.addToFavorites {
    z-index: 6;
    height: auto;
    min-height: 38px;

    .closeBannerBtn {
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .bannerText {
    color: colors.$main;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    max-width: calc(100% - 80px);

    &.addToFavorites {
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
    }
  }

  .closeBannerBtn {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 93px;

    @media (max-width: 768px) {
      right: 20px;
    }

    &.addToFavorites {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.desktopBanner {
    position: relative;
  }
}

.step {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: rgba(27, 129, 88, 0.1);
  flex-shrink: 0;
  margin-right: 10px;

  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: colors.$main;
  margin-top: 3px;
}
