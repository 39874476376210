@use "/src/sass/_colors.scss" as colors;

$border-radius: 30px;

.bgImage {
  width: 100%;
  display: block;
  max-width: 375px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.landingPage {
  width: 100%;
  position: relative;

  .landingProgressBar {
    position: relative;
  }

  .backButton {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: colors.$terraWhite;

    position: absolute;
    left: 15px;
    top: 30px;
  }

  .landingWrapper {
    background-color: colors.$terraWhite;
    border-radius: $border-radius;
    position: relative;
    margin-top: -22px;

    .landingBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding: 0 16px;
      @media (min-width: 768px) {
        max-width: 50%;
      }

      .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: colors.$textPrimary;
        margin-bottom: 21px;
        margin-top: 21px;
      }

      .moreItems {
        margin-bottom: 15px;
      }

      .text {
        color: colors.$textPrimary;
        margin-bottom: 50px;
        align-self: flex-start;
        margin-top: 15px;
      }

      .termsWrapper {
        display: flex;
        align-self: flex-start;

        .terms {
          font-weight: 700;
          font-size: 15px;
          line-height: 24px;
          color: colors.$main;
          margin-right: 8px;
          text-align: left;
        }
      }

      .amountIndicator {
        margin: 0;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: -14.5px;
      }
    }

    .brandLogo {
      width: 28%;
      height: auto;
      max-width: 100px;
      max-height: 100px;
      object-fit: contain;
      margin-bottom: 15px;
    }
  }
}

.needMore {
  margin-bottom: 52.5px;
  margin-top: 40px;
  text-align: center;
}

.linkText {
  color: colors.$textSecondary;
  margin-top: 32px;
  margin-bottom: 42.5px;

  .appLink {
    font-weight: 700;
    color: colors.$main;
  }
}

.carrefourDesc {
  margin-top: 32px;
  margin-bottom: 10px;
}

.category {
  margin-top: 17px;
}

.unlockButton {
  margin-bottom: 8px;

  .unlockText {
    margin-left: 10px;
  }
}

.scanBarCode {
  width: fit-content !important;
  padding: 10px 21px !important;
  margin-bottom: 44px;
  margin-top: 26px;
}

ul.termsSummary {
  list-style-type: disc;
  list-style-position: outside;
  margin-bottom: 50px;
  width: 90%;
  align-self: flex-start;
  padding-left: 22px;

  li {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: colors.$textPrimary;

    &::marker {
      font-size: 10px;
    }
  }
}

.amountDescription {
  width: 100%;
  border-top: 1px dashed colors.$textSecondary;
  border-bottom: 1px dashed colors.$textSecondary;
  padding: 12px 20px 19px;

  .amountLine {
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0px;
    }
    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: colors.$main;

      &.moneyValue {
        font-weight: 700;
      }
    }
  }
}

.brand {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .landingPage {
    .landingWrapper {
      margin-top: 0;
    }
  }
}
