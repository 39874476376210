@use "/src/sass/_colors.scss" as colors;
@use "/src/sass/_animations.scss" as animations;

.wrapper {
  &:global(.enter),
  &:global(.exit) {
    position: absolute;
  }

  &:global(.enter-active) {
    @include animations.animation(animations.$moveFromBottom);

    :global(.disable-on-enter) {
      display: none !important;
    }
  }

  &.backgroundGrey,
  .backgroundGrey {
    background-color: colors.$terraGrey !important;
  }

  .footerPresent {
    padding-bottom: 60px;
  }

  .noSidePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .width100 {
    &.pageContent {
      max-width: 100% !important;
    }
  }

  @media (min-width: 768px) {
    :global(.divider) {
      display: none;
    }

    .pageContent {
      border-radius: 0;
      max-width: 50%;
    }
  }
}

.pageContent {
  padding-top: 45px;

  &.pdTop25 {
    padding-top: 25px;
  }

  &.pdTop30 {
    padding-top: 30px;
  }
}
