.page {
  padding-top: 30px;
}

.yourRetailers {
  margin-bottom: 18px;
  padding: 0 16px;
}

.subheader {
  margin-bottom: 18px;
  padding: 0 16px;
}

.addMore {
  padding: 0 16px;
  margin-bottom: 50px;
}
