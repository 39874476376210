@use "/src/sass/_colors.scss" as vars;

.page {
  padding-top: 23px !important;
}

.description {
  margin-bottom: 16px;
}

.locationWrapper {
  display: flex;
  flex-direction: column;

  .locationName {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    margin: 0;
  }
  .locationAddress {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    margin: 0;
  }
}

.listAll {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 13px;

  .text {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: vars.$textPrimary;
  }

  .button {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-align: right;
    text-decoration-line: underline;
    color: vars.$main;
  }
}

.unacceptedItemsWrapper {
  margin-top: 6px;
  width: 100%;

  .unacceptedItemsDescription {
    font-weight: 700;
    margin-bottom: 16px;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
