@use "/src/sass/_colors.scss" as colors;

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  .pwDescription {
    margin-bottom: 20px;
  }

  form {
    margin-bottom: 40px;

    :global(.text-field) {
      margin-bottom: 20px;
    }

    :global(.main-button) {
      margin-top: 30px;
    }
  }

  .linkRow {
    display: flex;
    justify-content: center;
    margin: 39px 0 50px;
  }
}

.checkList {
  li {
    margin-top: 6px;

    &::before {
      content: "•";
      font-size: 25px;
      vertical-align: middle;
      line-height: 20px;
      margin-right: 13px;
    }

    &.invalid {
      &::before,
      span {
        color: colors.$error;
      }
    }
  }
}
