.wrapper {
  form {
    :global(.text-field) {
      margin-bottom: 20px;
    }

    .submitBtn {
      margin-top: 30px;
    }
  }

  .cancelBtn {
    margin-top: 20px;
  }

  :global(.delete-btn) {
    margin-top: 23px;
    margin-bottom: 40px;

    svg {
      margin-right: 8px;
    }
  }
}
