@use "/src/sass/_colors.scss" as colors;

.centering {
  position: fixed;
  background-color: colors.$terraWhite;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 17%);
  left: calc(100% - 60px);
  top: calc(100% - 120px);

  @media (min-width: 768px) {
    left: min(calc(100% - 60px), 85%);
  }

  @media (min-width: 991px) {
    left: min(calc(100% - 60px), 71%);
  }

  .navigateIcon {
    position: relative;
    left: 1.86px;
    bottom: 1.14px;
  }
}

.magickResizer {
  width: calc(100% - 30px);
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 98px;
  height: 40px;
  max-width: 768px;
  pointer-events: none;

  .filteringBtn {
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    right: 0;
    pointer-events: all;
  }
}

.popupContainer {
  .title {
    margin-top: 23px;
    margin-bottom: 18px;
  }

  .noLocationText {
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 18px;
    max-width: 80%;
  }

  ol.instructionList {
    margin-bottom: 30px;
    list-style: decimal;
    padding-left: 20px;

    li {
      font-weight: 400;
      font-size: 13px;
      line-height: 25px;
      color: colors.$textPrimary;
      margin: 0;
    }
  }

  .stillNeedHelp {
    color: colors.$textPrimary;
    margin-bottom: 30px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;

    .link {
      color: colors.$main;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .okayBtn {
    margin-bottom: 26px;
  }
}

.mapPageWrap {
  height: 100%;
  background-color: #f4f4f4;

  :global(.footer-nav-wrapper) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .searchBar {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
    z-index: 18;
    width: calc(100% - 30px);
  }

  .pointList {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 17;
    transition: opacity 1s ease-in-out;
  }

  .mapFooter {
    z-index: 16;
  }

  :global(.loading) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  :global(#map) {
    height: 100%;
  }

  :global(.map-popup-bubble) {
    position: absolute;
    top: -24px;
    left: -24px;
    background-color: rgba(169, 222, 152, 0.4);
    border-radius: 50%;
    box-sizing: content-box;
    width: 48px;
    height: 48px;

    &::after {
      content: "";
      background-color: colors.$terraGreen;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: block;
    }
  }

  :global(.map-popup-bubble-anchor) {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  :global(.map-popup-container) {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
  }
}
