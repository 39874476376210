.couponsWrapper {
  min-height: 100%;
  margin-top: -15px;
  padding: 0 8px 10px 8px;

  .itemsRecycled {
    margin-bottom: 25px;
  }

  .sortingPanel {
    overflow: auto;
  }
}
