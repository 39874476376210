@use "/src/sass/_colors.scss" as colors;

.wrapper {
  .container {
    padding-top: 25px;
    padding-bottom: 25px;

    .heading {
      margin-bottom: 34px;
    }

    img {
      margin-bottom: 48px;
    }

    .next {
      margin-top: 31px;
    }

    .cancel {
      color: colors.$main !important;
    }
  }
}
