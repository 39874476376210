@use "/src/sass/_colors.scss" as colors;

.manageCookies {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: colors.$main;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;

  &:hover {
    color: colors.$main;
  }
}
