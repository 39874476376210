@use "/src/sass/_colors.scss" as colors;

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  .description {
    margin-bottom: 20px;
    color: colors.$textPrimary;
  }

  .inputField {
    margin-bottom: 30px;
  }

  .signInLink {
    margin: 50px 0;
    display: flex;
    justify-content: center;
  }
}
