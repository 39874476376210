@use "/src/sass/_colors.scss" as colors;

.wrapper {
  .description {
    margin-bottom: 15px;
  }

  .saveBtn {
    margin-top: 39px;
    margin-bottom: 117px;
  }

  .dontHave {
    margin-bottom: 30px;
  }

  .createNow {
    margin-bottom: 30px;
  }
}

.cardsWrap {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-around;

  .cardContainer {
    min-width: 99px;
    display: flex;
    flex-direction: column;
    margin-right: 6px;
    margin-left: 30px;
    align-items: center;

    .carrefourCardBtn,
    .passCardBtn {
      position: relative;
      width: 76.77px;
      height: 45.8px;

      .cardImage {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }

      .tick {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #9f9f9f;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        top: 0;
        left: -30px;

        &.active {
          background-image: url("../../assets/icons/card-tick.svg");
        }
      }

      .border {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: 2px solid colors.$main;
        display: none;

        &.active {
          display: block;
        }
      }
    }

    .cardLabel {
      font-weight: 400;
      font-size: 13px;
      line-height: 25px;
      text-align: center;
      color: colors.$navGrey;

      &.active {
        color: colors.$main;
      }
    }
  }
}

.enterLoyalty {
  margin-bottom: 9px;
}

.inputWrapper {
  border-radius: 30px;
  padding: 11px 15px;

  input {
    text-align: center;
    width: 15px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: colors.$textPrimary;
    caret-color: colors.$textPrimary;

    &::placeholder {
      color: colors.$textSecondary;
    }
    &:focus::placeholder {
      color: transparent !important;
    }
  }
}

.stroke {
  @media (min-width: 768px) {
    padding: 0 20px;
  }
}

.skip {
  margin-bottom: 26px;
}
