@use "/src/sass/_colors.scss" as colors;

.icon {
  align-self: center;
  margin-top: 0;
  margin-bottom: 56px;
  width: auto;
  height: 77px;
}

.label {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 6px;
  color: #1b8158;
}

.select {
  margin-bottom: 30px !important;
}

.textArea {
  padding: 11px 24px;
  gap: 10px;
  width: 100%;
  height: 130px;
  background: #f1f1f1;
  border-radius: 20px;
  border: none;
  outline: none;
  color: #13220f;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  resize: none;
  margin-bottom: 30px;

  &::placeholder {
    color: #898989;
  }

  &:focus {
    border: 2px solid colors.$textSecondary;
  }
}

.storeField {
  margin-bottom: 30px;
}

.active {
  color: colors.$terraGreen;
}

.sendBtn {
  margin-bottom: 31px;
}

.pageContent {
  padding-top: 15px;
}
