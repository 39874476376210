@use "/src/sass/_colors.scss" as colors;

.checkboxWrap {
  input {
    width: 0;
    height: 0;
    appearance: none;

    &:focus + .checkButton {
      border: 2px solid colors.$main;
    }
  }

  .inputRow {
    display: flex;
  }

  .checkButton {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    background-color: colors.$grey;
    flex-shrink: 0;
    margin-right: 8px;
    position: relative;
    align-self: center;

    &:global(.big-text) {
      align-self: flex-start;
      margin-top: 8px;
    }

    label {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      display: none;
    }
  }

  input:checked + .checkButton {
    background-color: colors.$main;

    svg {
      display: block;
    }
  }
}
