.boxItem {
  margin: 10px;
}

.eanCodeUploadBlock {
  margin-top: 25px;
  width: 100%;


  .fileUpload {
    margin-right: 10px;
    color: #1976d2;
    cursor: pointer;

    flex-grow: 0;
  }

  input[type="file"] {
    display: none;
  }

  h3 {
    margin-bottom: 8px;
  }
}
