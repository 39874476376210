@use "/src/sass/_colors.scss" as vars;

.heading {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: vars.$textBlack;
  margin-top: 50px;
  margin-bottom: 34px;
}

.amount {
  margin-bottom: 20px;
}

.greenText {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 19px;
  color: vars.$main;
  text-align: center;
  display: block;
}

.button {
  width: 100%;
  margin-bottom: 21px;
}

.continueRecycling {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: vars.$main;
  margin-bottom: 39px;

  &:hover {
    color: vars.$main;
  }
}
