@use "/src/sass/_colors.scss" as colors;

.aimWrapper {
  width: 80%;
  height: 15%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  .aim {
    width: 20px;
    height: 20px;
    border-style: solid;
    border-color: #fff;
    display: block;
    position: absolute;
  }

  .aim1 {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px;
    border-top-left-radius: 4px;
  }

  .aim2 {
    top: 0;
    right: 0;
    border-width: 2px 2px 0 0;
    border-top-right-radius: 4px;
  }

  .aim3 {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0;
    border-bottom-right-radius: 4px;
  }

  .aim4 {
    bottom: 0;
    left: 0;
    border-width: 0 0 2px 2px;
    border-bottom-left-radius: 4px;
  }
}

.scanLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: centeredRotation 1s linear infinite;
}
@keyframes centeredRotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.hideCanvas {
  display: none;
}

.photoBtn {
  width: 66px;
  height: 66px;
  position: relative;
  border-radius: 50%;
  background-color: colors.$main;
  transform: translateX(-50%);
  left: 50%;
  margin-bottom: 43px;

  .innerCircle {
    width: 53px;
    height: 53px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 2px solid #dadada;
  }

  &:disabled {
    background-color: #dadada;

    .innerCircle {
      border: 2px solid colors.$main;
    }
  }
}

.error {
  margin-top: -10px !important;
  margin-bottom: 10px;
}
