.admin-heading {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 167%;
  color: #2a2a37;
  margin-bottom: 8px;
}

.admin-description {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #13220f;
  margin-bottom: 30px;
}
.rdp {
  --rdp-accent-color: #1976d2;
  .rdp-day_selected {
    background-color: #1976d2;
    &:hover {
      background-color: #2196f3;
    }
  }
}

.instructions {
  margin-top: 18px;
}
