.editWrapper {
  form {
    :global(.text-field) {
      margin-bottom: 20px;
    }

    .submitBtn {
      margin-top: 30px;
    }
  }

  .cancelBtn {
    margin-top: 20px;
  }

  .deleteBtn {
    margin-top: 23px;
    margin-bottom: 40px;

    svg {
      margin-right: 8px;
    }
  }
}

.deleteContainer {
  padding-top: 40px;
  padding-bottom: 30px;

  .title {
    margin-bottom: 18px;
  }

  .description {
    margin-bottom: 40px;
  }

  .cancelBtn {
    margin-top: 20px;
  }
}
