@use "/src/sass/_colors.scss" as color;

.progressBar {
  background-color: #9f9f9f;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 30px 30px 0 0;
  color: #fff;
  text-align: center;
  overflow: hidden;

  &:global(.roundedBottom) {
    top: auto;
    bottom: 0;
    border-radius: 0 0 30px 30px;
  }

  .description {
    position: relative;
    z-index: 1;
  }

  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: color.$main;
  }
}
