@use "/src/sass/_utils.scss" as utils;
@use "/src/sass/_colors.scss" as colors;
@use "/src/sass/_text.scss" as text;

$icons: "change-password", "retailer-list", "language", "faq", "tutorial",
  "terms", "privacy", "cookie", "logout";

:global(.profile-header) {
  box-shadow: 0px 10px 20px #005c35;
  z-index: 5;

  :global(.header-title) {
    text-transform: capitalize;
  }

  &::after,
  &::before {
    display: none;
  }
}
.wrapper {
  .content {
    &::before,
    &::after {
      z-index: 1;
    }

    .userDetails {
      padding-top: 35px;
      padding-bottom: 62px;
    }

    .nameIcon {
      transform: translate(-50%, -12px);
      border: 3px solid #ffffff;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.17);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      z-index: 6;
    }
  }

  .boxRow {
    bottom: -35px;
    z-index: 1;
  }

  .box {
    padding: 4px 0 20px;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    width: 33%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.17);

    & + .box {
      margin-left: 20px;
    }

    .value {
      font-size: 30px;
      line-height: 48px;
      font-weight: 600;
      color: colors.$successMedium;
    }

    .desc {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: colors.$main;
    }
  }

  .divider {
    border-top: 1px solid colors.$textSecondary;
    opacity: 0.3;
    margin: 10px 0;
  }

  .menu {
    .menuItems {
      padding-top: 48px;

      li {
        margin-bottom: 12px;
      }

      .menuItem {
        &::before {
          content: "";
          display: block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        &::after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          background-image: url(../../assets/icons/forward.svg);
          background-position: center;
          background-repeat: no-repeat;
          margin-left: auto;
        }

        @each $icon in $icons {
          &.#{$icon} {
            &::before {
              background-image: url(../../assets/profile-icons/#{$icon}.svg);
            }
          }
        }
      }

      .sectionName {
        @include text.descriptionText;
        color: colors.$textPrimary;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }

  .contactUs {
    margin-top: 19px;
    margin-bottom: 10px;
    background-color: rgba(colors.$main, 0.1);
    padding: 16px 5px;
    border-radius: 15px;
    flex-grow: 1;
    justify-content: center;

    :global(.tool-btn) {
      margin-right: 10px;
    }
  }

  .poweredBy {
    margin: 13px 0 17px;
  }
}
