.wrapper {
  .container {
    padding-top: 50px;
    padding-bottom: 34px;

    .heading {
      margin-bottom: 14px;
    }

    img {
      margin-bottom: 48px;
    }

    .next {
      margin-top: 28px;
    }
  }
}
