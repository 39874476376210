.wrapper {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;

    .heading {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 34px;
      text-align: center;
    }

    .apiErrImg {
      margin-bottom: 48px;
    }

    .retry {
      margin-top: 31px;
    }
  }
}
