.wrapper {
  .description {
    margin-bottom: 15px;
  }

  .saveBtn {
    margin-top: 30px;
    margin-bottom: 23px;
  }

  .takePicture {
    margin-bottom: 49px;
  }
}
