.recycleLogo {
  margin-top: 40px;
  margin-bottom: 24px;
  width: auto;
  height: 85px;
}

.container {
  padding: 0 9px;

  .popBubble {
    min-height: 80px;
    padding: 0 10px;

    justify-content: space-between;
  }
  .bubbleIcon {
    height: auto;
    flex-shrink: 0;

    margin-right: 0;
    margin-left: 12px;
  }

  .stepTextWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

p.topDescription {
  margin-bottom: 13px;
  font-weight: 700;
}
