@use "/src/sass/_colors.scss" as colors;

.categoryWrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 22px;

  @media (min-width: 992px) {
    column-gap: 5px;
  }

  @media (max-width: 370px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
  }

  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }

    .categoryIcon {
      width: 28px;
      height: auto;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .categoryText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 12px;
      text-align: center;
      color: colors.$main;
      max-width: 62px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
