.recycleLogo {
  margin-top: 48px;
  margin-bottom: 55px;
  height: 77px;
  width: auto;
}

.container {
  padding: 0 9px;

  .popBubble {
    min-height: 80px;
  }
  .bubbleIcon {
    margin-right: 10px;
    flex-shrink: 0;
  }
}
