.addAnotherBtn {
  margin-bottom: 26px;
  padding: 0 16px;
}

.heading {
  margin-bottom: 25px;
  padding: 0 16px;
}

.description {
  margin-bottom: 20px;
  max-width: 280px;
  padding: 0 16px;
  align-self: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}

.noRetailersContainer {
  margin-top: 33px;
  flex-grow: 1;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .illustration {
    margin-bottom: 30px;
  }

  .noRetailers {
    text-align: center;
    color: #000000;
  }
}
