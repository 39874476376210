@use "/src/sass/_colors.scss" as colors;

.wrapper {
  border-radius: 60px;
  padding: 10px 1px;
  font-size: 14px;
  line-height: 21px;
  color: #fff;

  :global(.button-content) {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  &:global(.no-bg-btn) {
    background-color: transparent;
    width: fit-content;
    padding: 0;
    color: colors.$main;
    line-height: 24px;
  }

  &:disabled {
    background-color: colors.$disabled !important;
    color: colors.$disabledText !important;
  }

  &:global(.inverted) {
    background-color: #fff;
    padding: 8px 0;
    border: 2px solid colors.$main !important;
    color: colors.$main !important;
  }
}

.logInButton {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
