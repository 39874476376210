.row {
  &.dashBoardContainer {
    padding: 0 24px;
    .card {
      .card-body {
        padding-bottom: 1rem !important;
        position: relative;

        .iconAlign {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-bottom: 12px;
        }

        h2 {
          font-size: 18px;
        }
      }
    }
  }
}

.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  padding: 0 24px;
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
}
