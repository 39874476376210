@use "/src/sass/_colors.scss" as colors;

.popContainer {
  margin-top: 14px;
}

.title {
  margin-top: 68px;
  margin-bottom: 13px;
}

.text {
  margin-bottom: 10px;
}

.cardLabel {
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: colors.$main;
}

.cardWrap {
  position: relative;
  width: 100%;
  max-width: 227px;
  height: auto;

  .cardImg {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  &.carrefourCard {
    margin-bottom: 7px;
  }

  &.passCard {
    margin-bottom: 29px;
  }
}
