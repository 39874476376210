@use "/src/sass/_colors.scss" as colors;

.swipingActionContainer {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: colors.$delete;
  border-radius: 15px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: colors.$terraGrey;
}

.swipingContainer {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.swipeableContentContainer {
  width: 100%;
  transition: transform 300ms linear;
  -webkit-transition: -webkit-transform 300ms linear;
  -webkit-transition: transform 300ms linear;
}

.actionButton {
  background-color: transparent;
}
